.main {
  text-align: center;
}

.image {
  width: 50%;
  height: auto;
  animation: rotate 30s infinite;
    border-radius: 50%;
    border: 2px solid #282c34;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    background: #282c34;
  margin: 0 auto 20px;
  /* Small */
  @media (max-width: 480px) {
        .image {
        width: 100%;
        }
    }
}

/* Animation für das Bild was sich leicht dreht */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Animation für den App-Header wo der Text reinrollt */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-540deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0);
  }
}


.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
    animation: rollIn 1s ease-in-out;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
